$(document).on('keydown', (function(e) {
  let elementExists = document.getElementById("carousel-show")
  if (!!elementExists) {
    if (!e.metaKey) {
      if (e.code === "ArrowLeft") {
        $('.carousel-show').carousel('prev')
        return false;
      }
      if (e.code === "ArrowRight") {
        $('.carousel-show').carousel('next')
        return false;
      }
    }
  }
}));

$(document).on("mouseover", () => {
  $('.carousel-indicators li a img').on('mouseover',function(){
    $(this).trigger('click');
  })  
});
