window.addEventListener("load", function() {
    //Like button
    // let LikesBtn = document.querySelectorAll('.like-button');
    // LikesBtn.forEach((element) => {
    //     element.addEventListener('click', function (e){
    //         e.preventDefault();
    //         element.classList.toggle('like');
    //     })
    // });

    // Prevent date input mess
    let StartAt = document.getElementById('start_at')
    let EndAt = document.getElementById('end_at')
    let People = document.getElementById('people')
    if (StartAt) { StartAt.addEventListener('change', () => { EndAt.focus() });}
    if (EndAt) {EndAt.addEventListener('change', () => { if(People) { People.focus() } });}


    //Menu
    openMenu();

    //Scroll Fixed
    // const header = document.querySelector('.big_header');
    // const FilterSection = document.querySelector('.filters-section')
    // if (header && FilterSection){
    //     // window.addEventListener('scroll', ()=>{
    //         header.classList.toggle('fixed-header');
    //         FilterSection.classList.toggle('fixed-header');
    //     // })
    // };

    //Extra Filters Desktop
    const ExtraFilters = document.querySelector('.extra-filters');
    const FiltersMinMax = document.querySelector('.filters-price');

    if (ExtraFilters) {
        ExtraFilters.addEventListener("click", function (e) {
            ExtraFilters.classList.toggle('_active');
            FiltersMinMax.classList.toggle('_active');
        });
    }

    //Extra Filters Desktop
    const ChoosePlace = document.querySelector('.choose-place');
    const MobileHide = document.querySelectorAll('.mobile-none');

    if (ChoosePlace) {
        ChoosePlace.addEventListener("change", function (e) {
            ChoosePlace.classList.toggle('_active');
            /*MobileHide.classList.toggle('_active');*/
            MobileHide.forEach(function (item){
                item.classList.add('_active');
            })
        });
    }

    //Extra Filters Desktop PopUp
    const searchingInput = document.querySelector('.searching-input');
    const accommodationFilters = document.querySelector('.accommodation-filters');

    if(searchingInput){
        searchingInput.addEventListener('click', function (){
            searchingInput.classList.toggle('_active');
            accommodationFilters.classList.toggle('_active');
            document.getElementById('query').focus();
        })
    }

    const div = document.querySelector( '.filters-section');
    let contentSection = document.querySelector('.container-content');

    contentSection.addEventListener('click', function (e){
        const withinBoundaries = e.composedPath().includes(div);
        MobileHide.forEach(function (item){
            item.classList.remove('_active');
        })
        if ( ! withinBoundaries ) {
            div.classList.remove('_active');
            if (accommodationFilters) {
                accommodationFilters.classList.remove('_active');
            }
            if (searchingInput) {
                searchingInput.classList.remove('_active');
            }
        }
    })

    //Swiper
    const swiper = new Swiper(".mySwiper", {
        freeMode: true,
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            "760": {
                slidesPerView: "3",
                spaceBetween: 0,
                pagination: {
                    el: "",
                },
            },
            "280": {
                slidesPerView: 1,
                pagination: {
                    el: ".swiper-pagination",
                    type: "fraction",
                },

            },
        },
    });
})

document.addEventListener("turbolinks:render", () => {
    openMenu();
});

function openMenu() {
    const iconMenu = document.querySelector('.menu__icon');
    const menuBody = document.querySelector('.menu__body');
    if (iconMenu) {
        iconMenu.addEventListener("click", function (e) {
            document.body.classList.toggle('_lock');
            iconMenu.classList.toggle('_active');
            menuBody.classList.toggle('_active');
        });
    }
}